import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { ScrollPanel } from "primereact/scrollpanel";
import "../../../assets/css/ScrollPanelDemo.css";
import "../../../assets/css/DataTableDemo.css";
import "../../../assets/css/DialogDemo.css";
import ConsultaServicio from "./ConsultaServicio";
import NotasServicio from "./NotasServicio";
import { RadioButton } from "primereact/radiobutton";
import { alignment, defaultDataType } from "export-xlsx";
import ExcelExport from "export-xlsx";
import { jsPDF } from "jspdf";
import { ProgressBar } from "primereact/progressbar";
import { saveAs } from "file-saver";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../Barra";


export default function PendientesEliminados(props) {
  //const classes = useStyles();
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [visibleFechas, setVisibleFechas] = useState(false);
  const [fechas, setFechas] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleUpload, setVisibleUpload] = useState(false);
  const toast = useRef(null);
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState(null);
  const [servicio, setServicio] = useState(null);
  const [grupo, setGrupo] = useState(null);
  const [radio, setRadio] = useState("tomado");
  const [val, setVal] = useState(null);
  const [agrupado, setAgrupado] = useState(null);
  const [progress, setProgress] = useState(false);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const { statusAcceso, statusAcceso2, obtenerAcceso, obtenerAcceso2 } =
    configuracionContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    serviciosAnulados,
    filtrosListadosServicios,
    serviciosAnuladosExcel,
    obtenerServiciosPendientesEliminados,
    obtenerNotas,
    guardarServicio,
    guardarFiltrosListadosServicios,
  } = serviciosContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Anulados" });
    }
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 2000);

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const groupBy = (prop) => (data) => {
    return data.reduce((dict, item) => {
      const { [prop]: _, ...rest } = item;
      dict[item[prop]] = [...(dict[item[prop]] || []), rest];
      return dict;
    }, {});
  };

  const calendar = (e) => {
    setVisibleFechas(true);
  };

  const exitClick = (e) => {
    navigate(`/`);
  };

  const onHideFechas = (e) => {
    setVisibleFechas(false);
  };

  const onHideUpload = (e) => {
    setVisibleUpload(false);
  };

  const aceptarFechas = (e) => {
    e.preventDefault();
    //Validar

    if (
      !fechas ||
      fechas.desde === undefined ||
      fechas.desde === null ||
      fechas.desde.trim() === ""
    ) {
      mensajeAlerta("Fecha desde es necesaria !.", "error");
      return;
    }

    if (
      !fechas ||
      fechas.hasta === undefined ||
      fechas.hasta === null ||
      fechas.hasta.trim() === ""
    ) {
      mensajeAlerta("Fecha hasta es necesaria !.", "error");
      return;
    }

    let fdesde = fechas.desde + "T00:00";
    let fhasta = fechas.hasta + "T23:59";

    setProgress(true);

    // Llamar al context
    obtenerServiciosPendientesEliminados({
      fdesde: fdesde,
      fhasta: fhasta,
    });
    onHideFechas();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Indicativo</span>
        {rowData.id}
      </Fragment>
    );
  };

  const indicativoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Indicativo</span>
        {rowData.indicativo}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const colorInicialTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Color Inicial</span>
        {rowData.colorInicio}
      </Fragment>
    );
  };

  const colorFinalTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Color Final</span>
        {rowData.color}
      </Fragment>
    );
  };

  const titularIdTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Socio Titular</span>
        {rowData.titularId}
      </Fragment>
    );
  };

  const nombreTitularTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre Titular</span>
        {rowData.nombreTitular}
      </Fragment>
    );
  };

  const TomadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Tomado</span>
        {rowData.tomado}
      </Fragment>
    );
  };

  const PasadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Pasado</span>
        {rowData.pasado}
      </Fragment>
    );
  };

  const SalidaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Salida</span>
        {rowData.salida}
      </Fragment>
    );
  };

  const LlegadaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Llegada</span>
        {rowData.llegada}
      </Fragment>
    );
  };

  const FinTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fin</span>
        {rowData.fin}
      </Fragment>
    );
  };

  const MovilTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Móvil</span>
        {rowData.movilId}
      </Fragment>
    );
  };

  const TripulacionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Tripulación</span>
        {rowData.tripulacionId}
      </Fragment>
    );
  };

  const DomicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const DocumentoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Documento</span>
        {rowData.numeroDocumento}
      </Fragment>
    );
  };

  const consultaSocio = (rowData) => {
    guardarServicio({
      ...rowData,
      origen: "despachos",
    });
    setVisible(true);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          style={{
            margin: 0,
            padding: 0,
          }}
          icon="pi pi-search"
          className="p-button-success p-button-text"
          onClick={() => {
            consultaSocio(rowData);
            obtenerNotas({ servicioId: rowData.id });
          }}
          tooltip="Datos del servicio"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, serviciosAnulados);
        doc.save("serviciosAnulados.pdf");
      });
    });
  };

  const exportExcel = () => {
    if (serviciosAnulados && serviciosAnulados.length > 0) {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(serviciosAnulados);
        const workbook = {
          Sheets: { data: worksheet },
          SheetNames: ["data"],
        };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        saveAsExcelFile(excelBuffer, "serviciosPendientesEliminados");
      });
    }
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        {/*
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        */}
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Servicios Pendientes Eliminados"></Tag>
      </div>
    </Fragment>
  );

  const renderHeaderFechas = () => {
    return (
      <div>
        <Tag value="Servicios Pendientes Eliminados"></Tag>
      </div>
    );
  };

  const renderFooterFechas = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarFechas}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideFechas}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <Fragment>
        <div align="left">
          <Tag value="Datos del Servicio"></Tag>
        </div>
      </Fragment>
    );
  };

  const onHide = (e) => {
    setVisible(false);
  };

  //console.log(serviciosAnulados);
  //console.log(statusAcceso);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Grid id="dataTableBotones" xs={12} sm={12} md={12}>
            <Button
              icon="pi pi-calendar"
              tooltip="Filtros"
              className="p-button-rounded p-button-help"
              style={{ marginLeft: "0em" }}
              onClick={calendar}
            />
            <Button
              icon="pi pi-times"
              tooltip="Cerrar"
              className="p-button-rounded p-button-primary"
              style={{ marginLeft: "1em" }}
              onClick={exitClick}
            />
            {progress && !serviciosAnulados ? (
              <ProgressBar
                mode="indeterminate"
                style={{ height: "6px" }}
              ></ProgressBar>
            ) : null}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ align: "center", margin: "1em" }}
            />

            <div className="datatable-responsive-demo pdatatable2">
              <div className="card">
                <Tooltip target=".export-buttons>button" position="bottom" />
                <DataTable
                  value={serviciosAnulados}
                  selection={selectedRow}
                  onSelectionChange={(e) => setSelectedRow(e.value)}
                  selectionMode="single"
                  dataKey="id"
                  header={header}
                  className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                  style={{ fontSize: "xx-small" }}
                  globalFilter={globalFilter}
                  emptyMessage="No hay datos."
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                  rows={10}
                  rowsPerPageOptions={[10, 20, 50]}
                  //paginatorLeft={paginatorLeft}
                  //paginatorRight={paginatorRight}
                >
                  <Column
                    field="id"
                    header="Indicativo"
                    className="colIndicativo"
                    body={idTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="nombre"
                    header="Nombre"
                    className="colNombre"
                    body={nombreTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="colorInicio"
                    header="Color Inicial"
                    className="colColor"
                    body={colorInicialTemplate}
                    sortable
                    //filter
                    //filterPlaceholder="Color"
                  ></Column>
                  <Column
                    field="color"
                    header="Color Final"
                    className="colColor"
                    body={colorFinalTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="titularId"
                    header="Socio Titular"
                    className="colMovil"
                    body={titularIdTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="nombreTitular"
                    header="Nombre Titular"
                    className="colNombre"
                    body={nombreTitularTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="tomado"
                    header="Tomado"
                    className="colHorarios"
                    body={TomadoTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="pasado"
                    header="Pasado"
                    className="colHorarios"
                    body={PasadoTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="salida"
                    header="Salida"
                    className="colHorarios"
                    body={SalidaTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="llegada"
                    header="Llegada"
                    className="colHorarios"
                    body={LlegadaTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="fin"
                    header="Fin"
                    className="colHorarios"
                    body={FinTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="movilId"
                    header="Móvil"
                    className="colMovil"
                    body={MovilTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="tripulacionId"
                    header="Tripulación"
                    className="colMovil"
                    body={TripulacionTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="domicilio"
                    header="Domicilio"
                    className="colDomicilio"
                    body={DomicilioTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="numeroDocumento"
                    header="Documento"
                    className="colMovil"
                    body={DocumentoTemplate}
                    sortable
                  ></Column>
                  <Column
                    className="colBotones"
                    body={actionBodyTemplate}
                  ></Column>
                </DataTable>
              </div>
            </div>
          </Grid>

          <Dialog
            header={renderHeaderFechas()}
            visible={visibleFechas}
            closable={false}
            footer={renderFooterFechas()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="desde"
                  label="Llegada Desde"
                  type="date"
                  fullWidth
                  variant="standard"
                  //onKeyDown={handleEnter}
                  value={fechas && fechas.desde ? fechas.desde : null}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFechas({
                      ...fechas,
                      desde: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="hasta"
                  label="Llegada Hasta"
                  type="date"
                  fullWidth
                  variant="standard"
                  //onKeyDown={handleEnter}
                  value={fechas && fechas.hasta ? fechas.hasta : null}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFechas({
                      ...fechas,
                      hasta: e.target.value,
                    });
                  }}
                />
              </Grid>

              {/*    
              <Grid
                item
                xs={6}
                md={6}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              >
                <Autocomplete
                  id="agrupar"
                  fullWidth
                  disabled={
                    filtrosListadosServicios &&
                    (filtrosListadosServicios.hcServicio ||
                      filtrosListadosServicios.hcAdjunto ||
                      filtrosListadosServicios.sinCoseguros ||
                      filtrosListadosServicios.conCoseguros)
                  }
                  options={agrupar}
                  defaultValue={grupo ? agrupar[grupo - 1] : null}
                  value={val}
                  disablePortal
                  getOptionLabel={(option) => option.nombre}
                  renderInput={(params) => (
                    <TextField {...params} label="Agrupar por" />
                  )}
                  //onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      setGrupo(value.id);
                      setVal(value);
                    } else {
                      setGrupo(null);
                      setVal(null);
                    }
                  }}
                />
              </Grid>
              */}

              <Grid
                item
                xs={6}
                md={6}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              ></Grid>
            </Grid>
          </Dialog>
          <Dialog
            header={renderHeader}
            visible={visible}
            className="p-dialog-despacho"
            icon="pi pi-external-link"
            closable={true}
            onHide={() => onHide()}
            //footer={renderFooter()}
            breakpoints={{ "960px": "75vw" }}
            style={{
              width: "100vw",
              padding: "0",
              overflowY: "visible",
            }}
            position="top-right"
          >
            <Grid container spacing={1} style={{ padding: "0" }}>
              <Grid item xs={12} md={8}>
                <ScrollPanel style={{ width: "100%", height: "100%" }}>
                  <div style={{ padding: "0em", lineHeight: "1.5" }}>
                    <ConsultaServicio />
                  </div>
                </ScrollPanel>
              </Grid>
              <Grid item xs={12} md={4}>
                <ScrollPanel
                  style={{ width: "100%", height: "100%" }}
                  className="custombar1"
                >
                  <div style={{ padding: "0em", lineHeight: "1.5" }}>
                    <NotasServicio />
                  </div>
                </ScrollPanel>
              </Grid>
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
